.Button {
  padding: 8px 12px;
  outline: none;
  border: none;
  background-color: white;
  box-shadow: 0px 0px 0px 1px #ccc;
  color: #565f65;
  height: 100%; }

.Button--active {
  border-bottom: 3px solid #eee; }

.Button--solid {
  background-color: #1176b8;
  box-shadow: 0px 0px 0px 1px #1176b8;
  color: white; }

.Button--small {
  padding: 4px 6px;
  font-size: 0.8em; }

.Button--block {
  width: 100%; }

.Button:hover {
  opacity: 0.75;
  cursor: pointer; }

.Button:disabled {
  cursor: not-allowed;
  opacity: 0.4; }

.Button--transparant {
  background: none;
  background-color: transparent;
  box-shadow: none; }
