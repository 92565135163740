.Body h2 {
  margin: 0px;
  padding-bottom: 15px;
  margin-bottom: 0px;
  border: 0px; }

.Body p {
  margin-top: 0px;
  margin-bottom: 10px; }

.Body ul {
  margin: 30px 0px; }

.Body img {
  width: 100%; }
