.Categories__Table {
  width: 100%;
  margin-bottom: 50px; }

.Categories__Table tr {
  text-align: left; }

.Categories__Table td, th {
  padding: 10px; }

.Categories__Table tr {
  border: 1px solid whitesmoke; }

.Categories__Table th:nth-child(3), .Categories__Table th:nth-child(6), .Categories__Table th:nth-child(7),
.Categories__Table td:nth-child(3), .Categories__Table td:nth-child(6), .Categories__Table td:nth-child(7) {
  text-align: right; }

.Categories__Table td:nth-child(4), .Categories__Table td:nth-child(5) {
  text-align: center; }

.ColorSquare {
  border-radius: 2px;
  height: 16px;
  width: 16px;
  border: 1px solid transparent;
  display: inline-block; }
