.Errors__Title {
  font-weight: bold;
  color: #B90E40;
  padding: 6px 0px; }

.Errors__List {
  list-style: disc;
  list-style-position: inside; }

.Errors__Item {
  color: #B90E40;
  padding: 2px; }
