.Tabs {
  width: 100%;
  display: flex;
  flex-direction: column; }

.Tabs__Wrapper {
  display: flex;
  border-bottom: 1px solid whitesmoke;
  padding: 0 16px;
  justify-content: center;
  overflow-x: auto;
  white-space: nowrap; }

.Tabs__Wrapper--left {
  display: flex;
  margin: 0;
  padding: 0; }

.Tabs__Tab {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  padding: 28px 50px;
  color: #565f65;
  cursor: pointer;
  align-items: center;
  display: flex;
  font-weight: lighter;
  font-size: 1.2em; }

.Tabs__Tab--active, .Tabs__Tab:hover {
  border-bottom-color: #1176b8;
  color: #1176b8; }

@media only screen and (max-width: 768px) {
  .Tabs__Wrapper {
    justify-content: flex-start; } }
