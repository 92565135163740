.Management h3 {
  font-size: 1.1em;
  font-weight: bold; }

.Management .col-lg-6 {
  margin-bottom: 25px; }

.Management h4 {
  margin-top: 20px;
  margin-bottom: 5px; }

.Management p {
  margin-bottom: 5px;
  margin-top: 0px; }
