.Timetable__Row {
  width: 100%;
  display: flex;
  padding: 10px 10px 10px 0;
  justify-content: space-between; }

.Timetable__Time {
  margin-right: 50px;
  width: 100px;
  flex-shrink: 0; }

.Timetable__Categories {
  flex: 1; }

.Timetable__Categories__Category:first-child {
  margin-bottom: 5px; }

.Timetable__Categories__Category {
  text-align: left; }

.Timetable__SpecialTitle {
  width: 100%;
  text-align: center;
  padding: 20px 0; }
