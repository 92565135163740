.Newsletter {
  text-align: center;
  background: #1176b8;
  background: -webkit-linear-gradient(to right, #1176b8, #59b4f0);
  background: linear-gradient(to right, #1176b8, #59b4f0);
  margin-top: 30px;
  margin-bottom: -31px;
  padding: 50px;
  color: white; }

.Newsletter p {
  margin-bottom: 30px;
  margin-top: 30px; }

.Newsletter h2 {
  border: none;
  padding-bottom: 0px; }

.Newsletter__Form {
  width: 500px;
  text-align: left;
  display: inline-block; }

.Newsletter__Form .Form__Input {
  border-radius: 1px;
  width: 370px; }

.Newsletter__Button__Wrapper {
  display: inline-block;
  width: 120px;
  margin-left: 10px; }

.Newsletter__Button {
  color: white;
  font-weight: bolder; }

@media only screen and (max-width: 580px) {
  .Newsletter__Form {
    width: 100%;
    text-align: center; }
  .Newsletter__Form .Form__Input {
    width: 100%; }
  .Newsletter__Button {
    width: auto;
    margin-top: 30px;
    margin-left: 0; } }
