.header {
  display: block;
  border-bottom: 1px solid #eeeeee; }
  .header .inner {
    display: block;
    max-width: 1140px;
    width: calc(100% - 100px);
    height: 100px;
    margin: 0 auto;
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 1.2em;
    color: black; }
    .header .inner .left {
      float: left;
      line-height: 100px; }
    .header .inner .left a {
      color: black;
      text-decoration: none; }
      .header .inner .left a img {
        height: 50px; }
    .header .inner .right {
      cursor: pointer;
      float: right;
      line-height: 100px;
      display: flex;
      text-transform: uppercase; }
      .header .inner .right .hamburger {
        padding-left: 16px; }

.headerMenu {
  position: fixed;
  background-color: white;
  height: calc(100% - 101px);
  width: 100%;
  top: 101px;
  left: 0;
  z-index: 999;
  transition: all .5s ease-out;
  opacity: 0;
  visibility: hidden; }

.headerMenu.is-active {
  opacity: .95;
  visibility: visible; }

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  border-radius: 0; }

.hamburger {
  outline: none;
  padding: 15px 0px; }

.hamburger:hover {
  opacity: 1; }

.hamburger-box {
  display: flex; }

@media only screen and (max-width: 480px) {
  .Header__Right__Text {
    visibility: hidden;
    width: 0; } }
