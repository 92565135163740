.LinkTabs {
  width: 100%;
  display: flex;
  flex-direction: column; }

.LinkTabs__Wrapper {
  display: flex;
  border-bottom: 1px solid whitesmoke;
  padding: 0 16px;
  justify-content: center;
  overflow-x: auto;
  white-space: nowrap; }

.LinkTabs__Wrapper--left {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none; }

.LinkTabs__Tab__Icon {
  font-size: 0.65em;
  padding-left: 6px;
  padding-top: 3px; }

.Info__SecondNav__Item__Icon {
  font-size: 0.65em;
  position: relative;
  top: -1px; }

.LinkTabs__Tab > li {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  padding: 28px 50px;
  color: #565f65;
  cursor: pointer;
  align-items: center;
  display: flex;
  font-weight: lighter;
  font-size: 1.2em; }

.LinkTabs__Tab--active > li, .LinkTabs__Tab:hover > li {
  border-bottom-color: #1176b8;
  color: #1176b8;
  text-decoration: none; }

.LinkTabs__Tab, .LinkTabs__Tab:hover, .LinkTabs__Tab:active, .LinkTabs__Tab:focus, .LinkTabs__Tab:visited {
  text-decoration: none; }

@media only screen and (max-width: 768px) {
  .LinkTabs__Wrapper {
    justify-content: flex-start; } }
