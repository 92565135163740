.CalendarDetail .row {
  margin-top: 30px;
  margin-bottom: 30px; }

.CalendarDetail__Heading {
  color: #e6e6e6;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.5px;
  font-size: 0.95em;
  min-height: auto; }

.CalendarDetail__Heading span {
  color: white;
  text-transform: none;
  font-weight: lighter;
  letter-spacing: normal;
  font-size: 1.5em;
  display: block;
  padding-top: 5px;
  font-family: "Raleway", sans-serif; }

.CalendarDetail__Championship {
  padding: 10px 0px;
  display: flex;
  align-items: center; }

.CalendarDetail__Championship .ChampionshipBadge {
  margin-left: 0px;
  margin-right: 8px; }
