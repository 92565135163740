.Heading {
  width: 100%;
  background: #1176b8;
  background: -webkit-linear-gradient(to right, #1176b8, #59b4f0);
  background: linear-gradient(to right, #1176b8, #59b4f0); }

.Heading__Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px; }

.Heading__Titles {
  display: flex;
  flex-direction: column;
  margin-right: 30px; }

.Heading__Action {
  flex-shrink: 0; }
  .Heading__Action .Heading__Action__Button {
    color: white;
    box-shadow: 0px 0px 0px 1px white;
    background-color: transparent; }
    .Heading__Action .Heading__Action__Button a {
      color: white;
      text-decoration: none; }

.Heading__Title {
  font-family: 'Oswald', sans-serif;
  color: white;
  font-weight: 700;
  font-size: 52px;
  font-style: italic;
  flex-grow: 0;
  text-transform: uppercase; }

.Heading__SubTitle {
  color: white;
  font-weight: 300;
  font-size: 32px;
  font-style: normal;
  flex-grow: 1; }

@media only screen and (max-width: 768px) {
  .Heading__Container {
    flex-direction: column; }
  .Heading__Action {
    margin-top: 30px; } }
