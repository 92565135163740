.Sponsors {
  margin: 0;
  margin-top: 30px;
  padding: 0; }

.Sponsors__Item {
  width: 100%;
  text-align: center;
  margin-bottom: 30px; }
  .Sponsors__Item img {
    width: 80%; }
