.Form__Label {
  font-weight: bold;
  display: block;
  padding: 12px 0px;
  margin-top: 8px; }

.Form__Dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-overflow: '';
  background: url(./drop-down-arrow.png) no-repeat calc(100% - 16px); }

.Form__Dropdown::-ms-expand {
  display: none; }

.Form__Input, .Form__Dropdown, .Form__Textarea {
  padding: 18px 16px;
  background-color: #f7f7f7;
  border: 0px;
  width: 100%;
  border-radius: 5px;
  border: 1.2px solid transparent;
  color: black;
  margin-left: -1.2px; }

.Form__Textarea {
  min-height: 150px; }

.Form__Input::placeholder, .Form__Dropdown::placeholder {
  color: #ababab; }

.Form__Input:focus, .Form__Dropdown:focus, .Form__Textarea:focus {
  outline: none !important;
  border: 1.2px solid #1176b8;
  background-color: white; }

.Form__Field__Invalid {
  border: 1.2px solid #F44336 !important;
  color: #F44336 !important; }

.Form__Field__Invalid::placeholder {
  color: #f99d97 !important; }

.Form__Checkbox {
  margin-right: 8px; }

.Form > .row {
  margin-bottom: 40px; }

.Form > .col-lg-6 {
  padding-left: 0px; }
