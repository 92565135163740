.Info__Content {
  margin-top: 50px; }

.Info__SecondNav {
  max-width: 300px;
  width: 100%;
  border-right: 1px solid whitesmoke; }

.Info__SecondNav__Title {
  padding: 10px 0 0;
  margin: 0 0 15px; }

.Info__SecondNav__List {
  list-style: none;
  padding: 0;
  margin: 0; }

.Info__SecondNav__Item,
.Info__SecondNav__Item:active,
.Info__SecondNav__Item:focus,
.Info__SecondNav__Item:hover,
.Info__SecondNav__Item:visited {
  text-decoration: none; }

.Info__SecondNav__Item li {
  padding: 10px 0;
  color: #565f65;
  font-weight: lighter;
  font-size: 1.1em; }

.Info__SecondNav__Item--active li {
  color: #1176b8; }

.InfoTabs__Tab__Icon {
  font-size: .65em;
  margin-left: 6px;
  padding-top: 2px; }

@media only screen and (max-width: 768px) {
  .Info__SecondNav {
    width: calc(100% + 30px);
    max-width: initial;
    border-bottom: 1px solid whitesmoke;
    border-right: none;
    margin-bottom: 20px;
    margin-top: -50px;
    margin-left: -15px;
    justify-content: center;
    display: flex; }
  .Info__SecondNav__Title {
    display: none; }
  .Info__SecondNav__Item {
    padding: 10px; }
  .Info__SecondNav__List {
    display: flex;
    width: 80%;
    flex-wrap: wrap; } }
