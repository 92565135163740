.Menu {
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: scroll;
  display: table; }
  .Menu ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 30px;
    display: table-cell;
    vertical-align: middle;
    overflow: scroll; }
    .Menu ul li {
      text-align: center;
      padding-bottom: 35px;
      font-size: 25px;
      font-family: "Raleway", sans-serif;
      font-weight: 400; }
      .Menu ul li a {
        color: black;
        text-decoration: none; }
      .Menu ul li a.active {
        color: #1176b8; }
      .Menu ul li img {
        margin-top: 50px;
        max-width: 300px;
        width: 100%; }

@media only screen and (max-width: 480px) {
  .Menu {
    display: block; }
  .Menu ul {
    display: block;
    width: 100%; }
  .Menu ul li img {
    margin-top: 0px; } }

@media only screen and (max-height: 640px) {
  .Menu {
    display: block; }
  .Menu ul {
    display: block;
    width: 100%; }
  .Menu ul li img {
    margin-top: 0px; } }

@media only screen and (max-height: 600px) {
  .Menu ul li {
    padding-bottom: 20px; } }
