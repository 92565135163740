.Contact__Content {
  margin-bottom: 50px; }

.Contact__Content .col-lg-6:nth-child(1) {
  padding-right: 20px; }

.Contact__Content .col-lg-6:nth-child(2) {
  padding-left: 14px; }

.Contact__Button {
  margin-top: 16px; }

.Contact__Map {
  width: 100% !important;
  height: auto !important;
  position: relative !important; }

.Contact__Map > div {
  position: relative !important; }

.Contact__Secretariaat--highlight {
  animation: fade-out 3s; }

@keyframes fade-out {
  from {
    background-color: #88c9f4; }
  to {
    background-color: white; } }

@media only screen and (max-width: 992px) {
  .Contact__Content .col-lg-6:nth-child(2) {
    margin-top: 50px; } }
