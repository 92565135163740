.Home {
  /* The footer will always have 30px above it. We don't want this behavior on the home-page. */
  margin-bottom: -30px; }

.Home,
.Home__Slider,
.slick-slider .slick-track,
.slick-slider .slick-list {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden; }

.Home__Slider {
  position: absolute; }

.Home__Slide {
  width: 100%;
  height: 100%;
  position: relative; }

.Home__Image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  z-index: -2;
  position: absolute; }

.Home__Content {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute; }

.Announcement {
  flex-grow: 1;
  padding: 50px 50px 100px 50px;
  align-self: flex-end;
  display: inline-flex;
  z-index: 2; }

.Announcement__Title {
  display: inline-block;
  transform: skew(-15deg);
  background: white;
  font-size: 2em;
  color: black;
  text-transform: uppercase;
  padding: 20px;
  font-weight: bolder;
  z-index: 2;
  font-style: italic; }

.Announcement__SubTitle {
  display: inline-block;
  transform: skew(-15deg);
  background: black;
  font-size: 2em;
  color: white;
  text-transform: uppercase;
  padding: 15px 30px 15px 100px;
  font-weight: bolder;
  margin-bottom: -20px;
  margin-left: -80px;
  align-self: flex-end;
  z-index: 1;
  font-style: italic; }

.Announcement__Title div,
.Announcement__SubTitle div {
  transform: skew(15deg); }

.Home__Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }

.slick-arrow {
  z-index: 1; }

.slick-prev {
  left: 0; }

.slick-next {
  right: 0; }

.Home__NavButton {
  background-color: white;
  opacity: 0.75;
  height: 40px;
  width: 40px;
  outline: none;
  z-index: 4; }

.Home__NavButton:active,
.Home__NavButton:focus,
.Home__NavButton:hover {
  background-color: white;
  opacity: 0.75; }

.Home__NavButton:before {
  font-family: inherit;
  content: none; }

.Home__NavButton__Icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: black; }

@media only screen and (max-width: 1150px) {
  .Home__Image {
    object-fit: cover; } }
