.Jaarvergunning__Content {
  margin-top: 50px; }

.Jaarvergunning__Terms__Label {
  text-decoration: underline;
  cursor: pointer; }

.YearLicenseType {
  box-shadow: 0 0 0 1px #ccc;
  margin-right: 16px;
  cursor: pointer;
  text-align: center;
  padding: 16px;
  background-color: white;
  border: none;
  outline: none; }

.YearLicenseType--active,
.YearLicenseType:hover {
  background: #1176b8;
  color: white;
  box-shadow: 0 0 0 1px transparent; }

.YearLicenseType__Title {
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
  padding: 16px; }
