.ToTop {
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 1039;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #2a9feb;
  text-align: center;
  color: white;
  cursor: pointer;
  border: 1px solid white; }

.ToTop-enter {
  opacity: 0.01; }

.ToTop-enter.ToTop-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.ToTop-leave {
  opacity: 1; }

.ToTop-leave.ToTop-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in; }
