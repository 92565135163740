.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  border-top: 1px solid #eeeeee;
  font-family: "Raleway", sans-serif;
  height: 100px; }
  .Footer ul {
    list-style-type: none; }
