.Calendar__Events {
  border-collapse: collapse;
  border-spacing: 0;
  overflow-x: auto;
  margin-top: 30px; }

.Calendar__Events table, .Calendar__Events tr {
  width: 100%; }

.Calendar__Event td {
  padding: 12px 8px;
  text-align: left;
  vertical-align: top;
  white-space: nowrap;
  vertical-align: middle;
  border-bottom: 1px #e6e6e6 solid; }

.Calendar__Event td:nth-child(1) {
  border-bottom: none; }

.Calendar__Event__Icon {
  color: #1176b8;
  font-size: 1.5em; }

.Calendar__Event td:nth-child(2) {
  width: 90px;
  height: 100px;
  padding-right: 20px; }

.Calendar__Event td:nth-child(3) {
  width: 50%;
  max-width: 350px;
  font-size: 1.05em;
  font-weight: bold;
  padding-right: 20px;
  word-wrap: break-word;
  white-space: pre-wrap; }

.Calendar__Event td:nth-child(3) span {
  max-width: 350px;
  line-height: 2em;
  font-weight: lighter;
  word-wrap: break-word;
  white-space: pre-wrap; }

.Calendar__Event td:nth-child(4), .Calendar__Event td:nth-child(5) {
  max-width: 100px;
  width: 100px;
  min-width: 100px;
  vertical-align: top; }

.Calendar__Event td:nth-child(6) {
  padding-left: 20px; }

.Calendar__Events tr:last-child td {
  border-bottom: none; }

@media only screen and (max-width: 768px) {
  .Calendar__Event td:nth-child(4), .Calendar__Event td:nth-child(5) {
    display: none; } }
