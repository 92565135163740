.ChampionshipBadge {
  border-radius: 50%;
  width: 21px;
  height: 21px;
  text-align: center;
  font-size: .75em;
  line-height: 20px;
  margin: 3px;
  float: left;
  border: 1px solid black;
  font-weight: bold;
  cursor: pointer; }
