.App--openMenu {
  height: 1vh;
  overflow: hidden; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.w-full {
  width: 100%; }

.mt-1 {
  margin-top: .25rem; }

.mt-2 {
  margin-top: .5rem; }

.mt-3 {
  margin-top: .75rem; }

.mt-4 {
  margin-top: 1rem; }

.mb-1 {
  margin-bottom: .25rem; }

.mb-2 {
  margin-bottom: .5rem; }

.mb-3 {
  margin-bottom: .75rem; }

.mb-4 {
  margin-bottom: 1rem; }

.mr-1 {
  margin-right: .25rem; }

.mr-2 {
  margin-right: .5rem; }

.mr-3 {
  margin-right: .75rem; }

.mr-4 {
  margin-right: 1rem; }

.max-w-xs {
  max-width: 20rem; }

.max-w-sm {
  max-width: 24rem; }

.max-w-md {
  max-width: 28rem; }

.max-w-lg {
  max-width: 32rem; }

.max-w-xl {
  max-width: 36rem; }

.max-w-2xl {
  max-width: 42rem; }

.max-w-3xl {
  max-width: 48rem; }

.max-w-4xl {
  max-width: 56rem; }

.max-w-5xl {
  max-width: 64rem; }
